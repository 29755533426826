import { LocalStorageName } from "./constants";

export const isPendingAction = (reducerType) => (action) => action.type.includes("pending") && action.type.includes(reducerType);

export const isRejectedAction = (reducerType) => (action) => action.type.includes("rejected") && action.type.includes(reducerType);

export const isFulfilledAction = (reducerType) => (action) => action.type.includes("fulfilled") && action.type.includes(reducerType);

export const isUserAuthenticated = () => {
  const posrednikData = JSON.parse(window.localStorage.getItem(LocalStorageName));
  if (posrednikData?.accessTokenExpire && posrednikData?.refreshTokenExpire) {
    return posrednikData.accessTokenExpire > Math.floor(Date.now() / 1000) || posrednikData.refreshTokenExpire > Math.floor(Date.now() / 1000);
  }
  return false;
};

export const createFormData = (data, formData = new FormData(), objKey = "") => {
  if (Array.isArray(data)) {
    if (data.length === 0) {
      formData.append(objKey, null);
    } else {
      data.forEach((item, index) => createFormData(item, formData, `${objKey}[${index}]`));
    }
  } else if (typeof data === "object" && !(data instanceof File || data instanceof Date)) {
    Object.keys(data).map((key) => createFormData(data[key], formData, objKey === "" ? key : `${objKey}.${key}`));
  } else {
    formData.append(objKey, data);
  }
  return formData;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const addToState = (data, state, replace = true, parseFields = []) => {
  if (parseFields.length > 0) {
    Object.keys(data).forEach((item) => {
      if (parseFields.includes(item) && data[item] !== "") {
        // eslint-disable-next-line no-param-reassign
        data[item] = JSON.parse(data[item]);
      }
    });
  }

  let isAdded = false;
  const newData = (state || []).map((i) => {
    if (i.id === data.id) {
      isAdded = true;
      return replace ? data : { ...i, ...data };
    }
    return i;
  });

  if (!isAdded) {
    newData.push(data);
  }
  return newData;
};

export const convertWord = (word) => word && word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();