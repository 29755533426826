import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";

export const registerUser = createAsyncThunk("authentication/register", async (values, { rejectWithValue }) => makeRequest("/auth/register", "POST", values, rejectWithValue));

export const login = createAsyncThunk("authentication/login", (values, { rejectWithValue }) => makeRequest("/auth/login", "POST", values, rejectWithValue, false));

export const logOut = createAsyncThunk("authentication/logout", (values, { rejectWithValue }) => makeRequest("/auth/logout", "POST", values, rejectWithValue));

export const verifyAccount = createAsyncThunk("authentication/emailVerification", (values, { rejectWithValue }) => makeRequest("/auth/verify-email", "POST", values, rejectWithValue));

export const resendVerificationCode = createAsyncThunk("authentication/emailResendVerification", async ({ email }, { rejectWithValue }) =>
  makeRequest("/auth/verify-email/resend", "POST", { email }, rejectWithValue)
);

export const resetPassword = createAsyncThunk("authentication/resetPassword", async (values, { rejectWithValue }) => makeRequest("/auth/reset-password", "POST", values, rejectWithValue));

export const confirmResetPassword = createAsyncThunk("authentication/confirmResetPassword", async (values, { rejectWithValue }) =>
  makeRequest("/auth/reset-password/confirm", "POST", values, rejectWithValue)
);

export const changePassword = createAsyncThunk("authentication/confirmResetPassword", async (values, { rejectWithValue }) =>
  makeRequest("/users/change-password", "POST", values, rejectWithValue)
);