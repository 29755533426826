/* eslint-disable no-useless-escape,no-underscore-dangle */
import LogoutIcon from "@mui/icons-material/Logout";
import CreateIcon from "@mui/icons-material/Create";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupIcon from "@mui/icons-material/Group";
import TopicIcon from "@mui/icons-material/Topic";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';
import HttpsIcon from '@mui/icons-material/Https';
import FactoryIcon from '@mui/icons-material/Factory';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

import ConstructionWorker from "../assets/icons/construction-worker.png";
import Gardener from "../assets/icons/gardener.png";
import Gastronomy from "../assets/icons/gastronomy.png";
import HotelWorker from "../assets/icons/hotel-worker.png";
import IndustrialCleaning from "../assets/icons/industrial-cleaning.png";
import InformationTechnology from "../assets/icons/information-technology.png";
import MedicalPerson from "../assets/icons/medical-person.png";
import Security from "../assets/icons/security.png";
import Transport from "../assets/icons/transport.png";
import Manufacturing from "../assets/icons/manufacturing.png";


export const LocalStorageName = "posrednik24";

export const IMAGE_FILE_EXTENSIONS = ["jpg", "jpeg", "png"];

export const UrlRegex =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const DrawerWidth = 240;
export const MobileWidth = 900;

export const UserVerificationSteps = {
  PERSONAL_INFORMATION: {
    label: "Personal Information",
    value: 0,
  },
  EDUCATION: {
    label: "Education",
    value: 2,
  },
  WORK_EXPERIENCE: {
    label: "Work Experience",
    value: 3,
  },
  SKILLS: {
    label: "Skills",
    value: 4,
  },
  BANK_DETAILS: {
    label: "Bank Details",
    value: 5,
  },
  JOB_EQUIPMENT: {
    label: "Job Equipment",
    value: 6,
  },
};

export const CompanyVerificationSteps = {
  COMPANY_DETAILS: {
    label: "Company Details",
    value: 0,
  },
  CONTACT_PERSON: {
    label: "Contact Person",
    value: 2,
  },
};

export const PromoterVerificationSteps = {
  PERSONAL_DETAILS: {
    label: "Personal Details",
    value: 0,
  },
  REFERRAL_CODE: {
    label: "Referral Code",
    value: 2,
  },
}

export const MainMenu = [
  {
    label: "Jobs",
    to: "/jobs",
  },
  {
    label: 'Services',
    children: [
      {
        label: 'Tax Refund',
        to: 'https://posrednik24.com/tax-refund/'
      },
      {
        label: 'Insurance',
        to: 'https://posrednik24.com/insurance/'
      },
      {
        label: 'Transport',
        to: 'https://posrednik24.com/transport/'
      },
      {
        label: 'Language Courses',
        to: 'https://posrednik24.com/language-courses/'
      },
    ]
  },
  {
    label: 'Experiences',
    children: [
      {
        label: 'Gallery',
        to: 'https://posrednik24.com/gallery/'
      },
      {
        label: 'Stories',
        to: 'https://posrednik24.com/stories/'
      },
    ],
  },
  {
    label: 'FAQ',
    to: 'https://posrednik24.com/faq/',
  },
  {
    label: "About Us",
    to: "https://posrednik24.com/about-us/",
  },
  {
    label: "Contact",
    to: "https://posrednik24.com/contact/",
  },
];

export const EnumFunctions = {
  _getProperties() {
    const properties = Object.getOwnPropertyNames(this).filter((i) => typeof this[i] !== "function");
    return properties.reduce((acc, i) => {
      acc[i] = this[i].label;
      return acc;
    }, {});
  },
  asList() {
    const obj = this._getProperties();
    return Object.keys(obj).map((i) => ({ label: obj[i], value: i }));
  },
  getLabels() {
    return Object.values(this._getProperties()).map((i) => i);
  },
  getValues() {
    return Object.keys(this._getProperties()).map((i) => i);
  },
  getLabel(val) {
    return this._getProperties()[val];
  },
  getItemByValue(val) {
    const properties = Object.getOwnPropertyNames(this).filter((i) => typeof this[i] !== "function");
    return this[properties.filter((i) => i === val)];
  }
};

export const Roles = {
  ADMIN: {
    value: "ADMIN",
    label: "Admin",
  },
  USER: {
    value: "USER",
    label: "User",
  },
  COMPANY: {
    value: "COMPANY",
    label: "Company",
  },
  PROMOTER: {
    value: "PROMOTER",
    label: "Promoter",
  },
  ...EnumFunctions,
};

export const DrawerItems = ({ isFirstLogin, role }) => ([
  {
    title: role === Roles.PROMOTER.value ? "Users" : "Dashboard",
    icon: DashboardIcon,
    route: "/dashboard",
  },
  {
    title: "Profile",
    icon: AccountBoxIcon,
    route: "/profile",
    roles: [Roles.USER.value, Roles.COMPANY.value, Roles.PROMOTER.value],
  },
  {
    title: "Jobs",
    icon: WorkIcon,
    route: "/jobs",
    roles: [Roles.ADMIN.value, Roles.COMPANY.value, Roles.PROMOTER.value],
  },
  {
    title: "Create Job",
    icon: CreateIcon,
    route: "/jobs/form",
    roles: [Roles.ADMIN.value, Roles.COMPANY.value],
  },
  {
    title: "Users",
    icon: GroupIcon,
    route: "/users",
    roles: [Roles.ADMIN.value],
  },
  {
    title: "Documents",
    icon: TopicIcon,
    route: "/documents",
    roles: [Roles.ADMIN.value, Roles.USER.value],
  },
  {
    title: "Applications",
    icon: AssignmentIcon,
    route: "/applications",
    roles: [Roles.USER.value],
  },
  {
    title: "Notifications",
    icon: NotificationsIcon,
    route: "/notifications",
  },
  {
    title: isFirstLogin ? "Set Password" : "Change Password",
    icon: HttpsIcon,
    route: "/change-password",
    roles: [Roles.USER.value, Roles.COMPANY.value]
  },
  {
    title: "Sign Out",
    icon: LogoutIcon,
  },
]);

export const JobCategories = {
  SEASONAL_JOB: {
    value: "SEASONAL_JOB",
    label: "Seasonal Job",
    color: '#C02026',
    textColor: 'white'
  },
  STUDENT_JOB: {
    value: "STUDENT_JOB",
    label: "Student Job",
    color: 'yellow',
    textColor: 'black'
  },
  VISA_JOB: {
    value: "VISA_JOB",
    label: "Visa Job",
    color: 'green',
    textColor: 'white'
  },
  EU_PASSPORT_JOB: {
    value: "EU_PASSPORT_JOB",
    label: "EU Passport Job",
    color: 'blue',
    textColor: 'white'
  },
  ...EnumFunctions,
};

export const NotificationTypes = {
  JOB_CREATED: {
    value: 'JOB_CREATED',
    label: 'Job Created',
    roles: [Roles.ADMIN.value]
  },
  JOB_UPDATED: {
    value: 'JOB_UPDATED',
    label: 'Job Updated',
    roles: [Roles.ADMIN.value]
  },
  JOB_STATUS_UPDATED: {
    value: 'JOB_STATUS_UPDATED',
    label: 'Job Status Updated',
    roles: [Roles.ADMIN.value]
  },
  USER_APPLIED: {
    value: 'USER_APPLIED',
    label: 'Job Application',
    roles: [Roles.ADMIN.value]
  },
  APPLICATION_STATUS_UPDATED: {
    value: 'APPLICATION_STATUS_UPDATED',
    label: 'Application Status Updated',
  },
  USER_REGISTERED: {
    value: 'USER_REGISTERED',
    label: 'User Registration',
    roles: [Roles.ADMIN.value]
  },
  USER_VERIFIED_EMAIL: {
    value: 'USER_VERIFIED_EMAIL',
    label: 'User Email Verification',
    roles: [Roles.ADMIN.value]
  },
  USER_VERIFIED_PROFILE: {
    value: 'USER_VERIFIED_PROFILE',
    label: 'User Profile Verification',
    roles: [Roles.ADMIN.value]
  },
  COMPANY_VERIFIED_PROFILE: {
    value: 'COMPANY_VERIFIED_PROFILE',
    label: 'Company Profile Verification',
    roles: [Roles.ADMIN.value]
  },
  ...EnumFunctions,
}

export const JobTimeTypes = {
  FULL_TIME: {
    value: "FULL_TIME",
    label: "Full Time",
  },
  PART_TIME: {
    value: "PART_TIME",
    label: "Part Time",
  },
  TEMPORARY: {
    value: "TEMPORARY",
    label: "Temporary",
  },
  INTERN: {
    value: "INTERN",
    label: "Intern",
  },
  ...EnumFunctions,
};

export const ApplicationStatus = {
  ACCEPTED: {
    value: "ACCEPTED",
    label: "Accepted",
  },
  REJECTED: {
    value: "REJECTED",
    label: "Rejected",
  },
  PENDING: {
    value: "PENDING",
    label: "Pending",
  },
  ...EnumFunctions,
};

export const SalaryType = {
  HOURLY: {
    value: "HOURLY",
    label: "Hourly",
  },
  MONTHLY: {
    value: "MONTHLY",
    label: "Monthly",
  },
  ...EnumFunctions,
};

export const JobIndustries = {
  GASTRONOMY: {
    value: "GASTRONOMY",
    label: "Gastronomy",
    icon: Gastronomy
  },
  INDUSTRIAL_CLEANING: {
    value: "INDUSTRIAL_CLEANING",
    label: "Industrial Cleaning",
    icon: IndustrialCleaning
  },
  GARDENING: {
    value: "GARDENING",
    label: "Gardening",
    icon: Gardener
  },
  CONSTRUCTION: {
    value: "CONSTRUCTION",
    label: "Construction",
    icon: ConstructionWorker
  },
  HOSPITALITY: {
    value: "HOSPITALITY",
    label: "Hospitality",
    icon: HotelWorker
  },
  MANUFACTURING: {
    value: "MANUFACTURING",
    label: "Manufacturing",
    icon: Manufacturing
  },
  SECURITY: {
    value: "SECURITY",
    label: "Security",
    icon: Security
  },
  MEDICAL_PERSONAL: {
    value: "MEDICAL_PERSONAL",
    label: "Medical Personal",
    icon: MedicalPerson
  },
  DISTRIBUTION_LOGISTICS: {
    value: "DISTRIBUTION_LOGISTICS",
    label: "Transport and Distribution",
    icon: Transport
  },
  PRODUCTION: {
    value: "PRODUCTION",
    label: "Production",
    icon: FactoryIcon
  },
  HR_PERSONAL_SERVICES: {
    value: "HR_PERSONAL_SERVICES",
    label: "HR and Personal Services",
    icon: SettingsAccessibilityIcon,
  },
  LOGISTICS: {
    value: "LOGISTICS",
    label: "Logistics",
    icon: EmojiTransportationIcon
  },
  INFORMATION_TECHNOLOGIES: {
    value: "INFORMATION_TECHNOLOGIES",
    label: "Information Technologies",
    icon: InformationTechnology
  },
  ...EnumFunctions,
};

export const RequiredDocuments = {
  PASSPORT: {
    value: "PASSPORT",
    label: "Passport",
  },
  ...EnumFunctions,
};

export const JobStatus = {
  ACTIVE: {
    value: "ACTIVE",
    label: "Active",
  },
  PAUSED: {
    value: "PAUSED",
    label: "Paused",
  },
  CLOSED: {
    value: "CLOSED",
    label: "Closed",
  },
};

export const hobbies = [
  "Photography",
  "Football",
  "Basketball",
  "Tennis",
  "Table Tennis",
  "Badminton",
  "Yoga",
  "Art",
  "Skiing",
  "Cycling",
  "Pottery",
  "Movies",
  "Reading",
  "Dance",
  "Running",
  "Swimming",
  "Mountain climbing",
  "Volleyball",
  "Board games",
  "Writing",
  "Design",
  "Painting",
  "Languages",
  "Camping",
];

export const Skills = [
  "Communication",
  "Creativity",
  "Problem-solving",
  "Teamwork",
  "Leadership",
  "Organization",
  "Work ethic",
  "Attention to detail",
  "Public-speaking",
  "Microsoft Office",
  "Social Media",
  "SEO",
  "People management",
  "Project management",
  "Time management",
  "Logistics",
  "Research skills",
  "Analytical Skills",
  "Critical thinking",
  "Decision-making skills",
  "Planning",
  "Scheduling",
  "Motivation",
  "Strategic thinking",
  "Persuasion skills",
  "Positivity",
  "Adaptability",
];

export const LanguageLevels = ["Native", "A1", "A2", "B1", "B2", "C1", "C2"];

export const languages = [
  { code: 'af', name: 'Afrikaans' },
  { code: 'ar', name: 'Arabic' },
  { code: 'hy', name: 'Armenian' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'be', name: 'Belarusian' },
  { code: 'bn', name: 'Bengali (Bangla)' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'ca', name: 'Catalan' },
  { code: 'ch', name: 'Chamorro' },
  { code: 'ce', name: 'Chechen' },
  { code: 'zh', name: 'Chinese' },
  { code: 'hr', name: 'Croatian' },
  { code: 'cs', name: 'Czech' },
  { code: 'da', name: 'Danish' },
  { code: 'dv', name: 'Divehi, Dhivehi, Maldivian' },
  { code: 'nl', name: 'Dutch' },
  { code: 'en', name: 'English' },
  { code: 'eo', name: 'Esperanto' },
  { code: 'et', name: 'Estonian' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'gl', name: 'Galician' },
  { code: 'gd', name: 'Gaelic (Scottish)' },
  { code: 'gv', name: 'Gaelic (Manx)' },
  { code: 'ka', name: 'Georgian' },
  { code: 'de', name: 'German' },
  { code: 'el', name: 'Greek' },
  { code: 'kl', name: 'Greenlandic' },
  { code: 'he', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'is', name: 'Icelandic' },
  { code: 'id, in', name: 'Indonesian' },
  { code: 'ga', name: 'Irish' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'kn', name: 'Kannada' },
  { code: 'ks', name: 'Kashmiri' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'kg', name: 'Kongo' },
  { code: 'ko', name: 'Korean' },
  { code: 'ku', name: 'Kurdish' },
  { code: 'la', name: 'Latin' },
  { code: 'lv', name: 'Latvian (Lettish)' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'lg', name: 'Luganda, Ganda' },
  { code: 'lb', name: 'Luxembourgish' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'mg', name: 'Malagasy' },
  { code: 'ms', name: 'Malay' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mt', name: 'Maltese' },
  { code: 'mh', name: 'Marshallese' },
  { code: 'mo', name: 'Moldavian' },
  { code: 'mn', name: 'Mongolian' },
  { code: 'ne', name: 'Nepali' },
  { code: 'no', name: 'Norwegian' },
  { code: 'fa', name: 'Persian (Farsi)' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'pa', name: 'Punjabi (Eastern)' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ru', name: 'Russian' },
  { code: 'sr', name: 'Serbian' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'so', name: 'Somali' },
  { code: 'es', name: 'Spanish' },
  { code: 'su', name: 'Sundanese' },
  { code: 'sw', name: 'Swahili (Kiswahili)' },
  { code: 'ss', name: 'Swati' },
  { code: 'sv', name: 'Swedish' },
  { code: 'tl', name: 'Tagalog' },
  { code: 'ty', name: 'Tahitian' },
  { code: 'tg', name: 'Tajik' },
  { code: 'ta', name: 'Tamil' },
  { code: 'tt', name: 'Tatar' },
  { code: 'te', name: 'Telugu' },
  { code: 'th', name: 'Thai' },
  { code: 'bo', name: 'Tibetan' },
  { code: 'ti', name: 'Tigrinya' },
  { code: 'to', name: 'Tonga' },
  { code: 'ts', name: 'Tsonga' },
  { code: 'tr', name: 'Turkish' },
  { code: 'tk', name: 'Turkmen' },
  { code: 'ug', name: 'Uyghur' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'uz', name: 'Uzbek' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'cy', name: 'Welsh' },
]

export const DrivingLicenses = {
  A: {
    value: "A",
    label: "A",
  },
  A1: {
    value: "A1",
    label: "A1",
  },
  B: {
    value: "B",
    label: "B",
  },
  BE: {
    value: "BE",
    label: "BE",
  },
  C1: {
    value: "C1",
    label: "C1",
  },
  C1E: {
    value: "C1E",
    label: "C1E",
  },
  C: {
    value: "C",
    label: "C",
  },
  CE: {
    value: "CE",
    label: "CE",
  },
  D1: {
    value: "D1",
    label: "D1",
  },
  D1E: {
    value: "D1E",
    label: "D1E",
  },
  D: {
    value: "D",
    label: "D",
  },
  DE: {
    value: "DE",
    label: "DE",
  },
  ...EnumFunctions,
}

export const INDUSTRIES = {
  "INDUSTRY": {
    "label": "Industry",
    "value": "INDUSTRY"
  },
  "ACCOUNTING": {
    "label": "Accounting",
    "value": "ACCOUNTING"
  },
  "AIRLINES_AVIATION": {
    "label": "Airlines/Aviation",
    "value": "AIRLINES_AVIATION"
  },
  "ALTERNATIVE_DISPUTE_RESOLUTION": {
    "label": "Alternative Dispute Resolution",
    "value": "ALTERNATIVE_DISPUTE_RESOLUTION"
  },
  "ALTERNATIVE_MEDICINE": {
    "label": "Alternative Medicine",
    "value": "ALTERNATIVE_MEDICINE"
  },
  "ANIMATION": {
    "label": "Animation",
    "value": "ANIMATION"
  },
  "APPAREL_FASHION": {
    "label": "Apparel/Fashion",
    "value": "APPAREL_FASHION"
  },
  "ARCHITECTURE_PLANNING": {
    "label": "Architecture/Planning",
    "value": "ARCHITECTURE_PLANNING"
  },
  "ARTS_CRAFTS": {
    "label": "Arts/Crafts",
    "value": "ARTS_CRAFTS"
  },
  "AUTOMOTIVE": {
    "label": "Automotive",
    "value": "AUTOMOTIVE"
  },
  "AVIATION_AEROSPACE": {
    "label": "Aviation/Aerospace",
    "value": "AVIATION_AEROSPACE"
  },
  "BANKING_MORTGAGE": {
    "label": "Banking/Mortgage",
    "value": "BANKING_MORTGAGE"
  },
  "BIOTECHNOLOGY_GREENTECH": {
    "label": "Biotechnology/Greentech",
    "value": "BIOTECHNOLOGY_GREENTECH"
  },
  "BROADCAST_MEDIA": {
    "label": "Broadcast Media",
    "value": "BROADCAST_MEDIA"
  },
  "BUILDING_MATERIALS": {
    "label": "Building Materials",
    "value": "BUILDING_MATERIALS"
  },
  "BUSINESS_SUPPLIES_EQUIPMENT": {
    "label": "Business Supplies/Equipment",
    "value": "BUSINESS_SUPPLIES_EQUIPMENT"
  },
  "CAPITAL_MARKETS_HEDGE_FUND_PRIVATE_EQUITY": {
    "label": "Capital Markets/Hedge Fund/Private Equity",
    "value": "CAPITAL_MARKETS_HEDGE_FUND_PRIVATE_EQUITY"
  },
  "CHEMICALS": {
    "label": "Chemicals",
    "value": "CHEMICALS"
  },
  "CIVIC_SOCIAL_ORGANIZATION": {
    "label": "Civic/Social Organization",
    "value": "CIVIC_SOCIAL_ORGANIZATION"
  },
  "CIVIL_ENGINEERING": {
    "label": "Civil Engineering",
    "value": "CIVIL_ENGINEERING"
  },
  "COMMERCIAL_REAL_ESTATE": {
    "label": "Commercial Real Estate",
    "value": "COMMERCIAL_REAL_ESTATE"
  },
  "COMPUTER_GAMES": {
    "label": "Computer Games",
    "value": "COMPUTER_GAMES"
  },
  "COMPUTER_HARDWARE": {
    "label": "Computer Hardware",
    "value": "COMPUTER_HARDWARE"
  },
  "COMPUTER_NETWORKING": {
    "label": "Computer Networking",
    "value": "COMPUTER_NETWORKING"
  },
  "COMPUTER_SOFTWARE_ENGINEERING": {
    "label": "Computer Software/Engineering",
    "value": "COMPUTER_SOFTWARE_ENGINEERING"
  },
  "COMPUTER_NETWORK_SECURITY": {
    "label": "Computer/Network Security",
    "value": "COMPUTER_NETWORK_SECURITY"
  },
  "CONSTRUCTION": {
    "label": "Construction",
    "value": "CONSTRUCTION"
  },
  "CONSUMER_ELECTRONICS": {
    "label": "Consumer Electronics",
    "value": "CONSUMER_ELECTRONICS"
  },
  "CONSUMER_GOODS": {
    "label": "Consumer Goods",
    "value": "CONSUMER_GOODS"
  },
  "CONSUMER_SERVICES": {
    "label": "Consumer Services",
    "value": "CONSUMER_SERVICES"
  },
  "COSMETICS": {
    "label": "Cosmetics",
    "value": "COSMETICS"
  },
  "DAIRY": {
    "label": "Dairy",
    "value": "DAIRY"
  },
  "DEFENSE_SPACE": {
    "label": "Defense/Space",
    "value": "DEFENSE_SPACE"
  },
  "DESIGN": {
    "label": "Design",
    "value": "DESIGN"
  },
  "E_LEARNING": {
    "label": "E-Learning",
    "value": "E_LEARNING"
  },
  "EDUCATION_MANAGEMENT": {
    "label": "Education Management",
    "value": "EDUCATION_MANAGEMENT"
  },
  "ELECTRICAL_ELECTRONIC_MANUFACTURING": {
    "label": "Electrical/Electronic Manufacturing",
    "value": "ELECTRICAL_ELECTRONIC_MANUFACTURING"
  },
  "ENTERTAINMENT_MOVIE_PRODUCTION": {
    "label": "Entertainment/Movie Production",
    "value": "ENTERTAINMENT_MOVIE_PRODUCTION"
  },
  "ENVIRONMENTAL_SERVICES": {
    "label": "Environmental Services",
    "value": "ENVIRONMENTAL_SERVICES"
  },
  "EVENTS_SERVICES": {
    "label": "Events Services",
    "value": "EVENTS_SERVICES"
  },
  "EXECUTIVE_OFFICE": {
    "label": "Executive Office",
    "value": "EXECUTIVE_OFFICE"
  },
  "FACILITIES_SERVICES": {
    "label": "Facilities Services",
    "value": "FACILITIES_SERVICES"
  },
  "FARMING": {
    "label": "Farming",
    "value": "FARMING"
  },
  "FINANCIAL_SERVICES": {
    "label": "Financial Services",
    "value": "FINANCIAL_SERVICES"
  },
  "FINE_ART": {
    "label": "Fine Art",
    "value": "FINE_ART"
  },
  "FISHERY": {
    "label": "Fishery",
    "value": "FISHERY"
  },
  "FOOD_PRODUCTION": {
    "label": "Food Production",
    "value": "FOOD_PRODUCTION"
  },
  "FOOD_BEVERAGES": {
    "label": "Food/Beverages",
    "value": "FOOD_BEVERAGES"
  },
  "FUNDRAISING": {
    "label": "Fundraising",
    "value": "FUNDRAISING"
  },
  "FURNITURE": {
    "label": "Furniture",
    "value": "FURNITURE"
  },
  "GAMBLING_CASINOS": {
    "label": "Gambling/Casinos",
    "value": "GAMBLING_CASINOS"
  },
  "GLASS_CERAMICS/CONCRETE": {
    "label": "Glass/Ceramics/Concrete",
    "value": "GLASS_CERAMICS/CONCRETE"
  },
  "GOVERNMENT_ADMINISTRATION": {
    "label": "Government Administration",
    "value": "GOVERNMENT_ADMINISTRATION"
  },
  "GOVERNMENT_RELATIONS": {
    "label": "Government Relations",
    "value": "GOVERNMENT_RELATIONS"
  },
  "GRAPHIC_DESIGN_WEB DESIGN": {
    "label": "Graphic Design/Web Design",
    "value": "GRAPHIC_DESIGN_WEB DESIGN"
  },
  "HEALTH_FITNESS": {
    "label": "Health/Fitness",
    "value": "HEALTH_FITNESS"
  },
  "HIGHER_EDUCATION_ACADEMIA": {
    "label": "Higher Education/Academia",
    "value": "HIGHER_EDUCATION_ACADEMIA"
  },
  "HOSPITAL_HEALTH_CARE": {
    "label": "Hospital/Health Care",
    "value": "HOSPITAL_HEALTH_CARE"
  },
  "HOSPITALITY": {
    "label": "Hospitality",
    "value": "HOSPITALITY"
  },
  "HUMAN_RESOURCES_HR": {
    "label": "Human Resources/HR",
    "value": "HUMAN_RESOURCES_HR"
  },
  "IMPORT_EXPORT": {
    "label": "Import/Export",
    "value": "IMPORT_EXPORT"
  },
  "INDIVIDUAL_FAMILY_SERVICES": {
    "label": "Individual/Family Services",
    "value": "INDIVIDUAL_FAMILY_SERVICES"
  },
  "INDUSTRIAL_AUTOMATION": {
    "label": "Industrial Automation",
    "value": "INDUSTRIAL_AUTOMATION"
  },
  "INFORMATION_SERVICES": {
    "label": "Information Services",
    "value": "INFORMATION_SERVICES"
  },
  "INFORMATION_TECHNOLOGY_IT": {
    "label": "Information Technology/IT",
    "value": "INFORMATION_TECHNOLOGY_IT"
  },
  "INSURANCE": {
    "label": "Insurance",
    "value": "INSURANCE"
  },
  "INTERNATIONAL_AFFAIRS": {
    "label": "International Affairs",
    "value": "INTERNATIONAL_AFFAIRS"
  },
  "INTERNATIONAL_TRADE_DEVELOPMENT": {
    "label": "International Trade/Development",
    "value": "INTERNATIONAL_TRADE_DEVELOPMENT"
  },
  "INTERNET": {
    "label": "Internet",
    "value": "INTERNET"
  },
  "INVESTMENT_BANKING_VENTURE": {
    "label": "Investment Banking/Venture",
    "value": "INVESTMENT_BANKING_VENTURE"
  },
  "INVESTMENT_MANAGEMENT_HEDGE_FUND_PRIVATE_EQUITY": {
    "label": "Investment Management/Hedge Fund/Private Equity",
    "value": "INVESTMENT_MANAGEMENT_HEDGE_FUND_PRIVATE_EQUITY"
  },
  "JUDICIARY": {
    "label": "Judiciary",
    "value": "JUDICIARY"
  },
  "LAW_ENFORCEMENT": {
    "label": "Law Enforcement",
    "value": "LAW_ENFORCEMENT"
  },
  "LAW_PRACTICE_LAW_FIRMS": {
    "label": "Law Practice/Law Firms",
    "value": "LAW_PRACTICE_LAW_FIRMS"
  },
  "LEGAL_SERVICES": {
    "label": "Legal Services",
    "value": "LEGAL_SERVICES"
  },
  "LEGISLATIVE_OFFICE": {
    "label": "Legislative Office",
    "value": "LEGISLATIVE_OFFICE"
  },
  "LEISURE_TRAVEL": {
    "label": "Leisure/Travel",
    "value": "LEISURE_TRAVEL"
  },
  "LIBRARY": {
    "label": "Library",
    "value": "LIBRARY"
  },
  "LOGISTICS_PROCUREMENT": {
    "label": "Logistics/Procurement",
    "value": "LOGISTICS_PROCUREMENT"
  },
  "LUXURY_GOODS_JEWELRY": {
    "label": "Luxury Goods/Jewelry",
    "value": "LUXURY_GOODS_JEWELRY"
  },
  "MACHINERY": {
    "label": "Machinery",
    "value": "MACHINERY"
  },
  "MANAGEMENT_CONSULTING": {
    "label": "Management Consulting",
    "value": "MANAGEMENT_CONSULTING"
  },
  "MARITIME": {
    "label": "Maritime",
    "value": "MARITIME"
  },
  "MARKET_RESEARCH": {
    "label": "Market Research",
    "value": "MARKET_RESEARCH"
  },
  "MARKETING_ADVERTISING_SALES": {
    "label": "Marketing/Advertising/Sales",
    "value": "MARKETING_ADVERTISING_SALES"
  },
  "MECHANICAL_OR INDUSTRIAL_ENGINEERING": {
    "label": "Mechanical or Industrial Engineering",
    "value": "MECHANICAL_OR INDUSTRIAL_ENGINEERING"
  },
  "MEDIA_PRODUCTION": {
    "label": "Media Production",
    "value": "MEDIA_PRODUCTION"
  },
  "MEDICAL_EQUIPMENT": {
    "label": "Medical Equipment",
    "value": "MEDICAL_EQUIPMENT"
  },
  "MEDICAL_PRACTICE": {
    "label": "Medical Practice",
    "value": "MEDICAL_PRACTICE"
  },
  "MENTAL_HEALTH CARE": {
    "label": "Mental Health Care",
    "value": "MENTAL_HEALTH CARE"
  },
  "MILITARY_INDUSTRY": {
    "label": "Military Industry",
    "value": "MILITARY_INDUSTRY"
  },
  "MINING_METALS": {
    "label": "Mining/Metals",
    "value": "MINING_METALS"
  },
  "MOTION_PICTURES_FILM": {
    "label": "Motion Pictures/Film",
    "value": "MOTION_PICTURES_FILM"
  },
  "MUSEUMS_INSTITUTIONS": {
    "label": "Museums/Institutions",
    "value": "MUSEUMS_INSTITUTIONS"
  },
  "MUSIC": {
    "label": "Music",
    "value": "MUSIC"
  },
  "NANOTECHNOLOGY": {
    "label": "Nanotechnology",
    "value": "NANOTECHNOLOGY"
  },
  "NEWSPAPERS_JOURNALISM": {
    "label": "Newspapers/Journalism",
    "value": "NEWSPAPERS_JOURNALISM"
  },
  "NON_PROFIT_VOLUNTEERING": {
    "label": "Non-Profit/Volunteering",
    "value": "NON_PROFIT_VOLUNTEERING"
  },
  "OIL_ENERGY/SOLAR/GREENTECH": {
    "label": "Oil/Energy/Solar/Greentech",
    "value": "OIL_ENERGY/SOLAR/GREENTECH"
  },
  "ONLINE_PUBLISHING": {
    "label": "Online Publishing",
    "value": "ONLINE_PUBLISHING"
  },
  "OTHER_INDUSTRY": {
    "label": "Other Industry",
    "value": "OTHER_INDUSTRY"
  },
  "OUTSOURCING_OFFSHORING": {
    "label": "Outsourcing/Offshoring",
    "value": "OUTSOURCING_OFFSHORING"
  },
  "PACKAGE_FREIGHT_DELIVERY": {
    "label": "Package/Freight Delivery",
    "value": "PACKAGE_FREIGHT_DELIVERY"
  },
  "PACKAGING_CONTAINERS": {
    "label": "Packaging/Containers",
    "value": "PACKAGING_CONTAINERS"
  },
  "PAPER_FOREST_PRODUCTS": {
    "label": "Paper/Forest Products",
    "value": "PAPER_FOREST_PRODUCTS"
  },
  "PERFORMING_ARTS": {
    "label": "Performing Arts",
    "value": "PERFORMING_ARTS"
  },
  "PHARMACEUTICALS": {
    "label": "Pharmaceuticals",
    "value": "PHARMACEUTICALS"
  },
  "PHILANTHROPY": {
    "label": "Philanthropy",
    "value": "PHILANTHROPY"
  },
  "PHOTOGRAPHY": {
    "label": "Photography",
    "value": "PHOTOGRAPHY"
  },
  "POLITICAL_ORGANIZATION": {
    "label": "Political Organization",
    "value": "POLITICAL_ORGANIZATION"
  },
  "PRIMARY_SECONDARY_EDUCATION": {
    "label": "Primary/Secondary Education",
    "value": "PRIMARY_SECONDARY_EDUCATION"
  },
  "PRINTING": {
    "label": "Printing",
    "value": "PRINTING"
  },
  "PROFESSIONAL_TRAINING": {
    "label": "Professional Training",
    "value": "PROFESSIONAL_TRAINING"
  },
  "PROGRAM_DEVELOPMENT": {
    "label": "Program Development",
    "value": "PROGRAM_DEVELOPMENT"
  },
  "PUBLIC_RELATIONS_PR": {
    "label": "Public Relations/PR",
    "value": "PUBLIC_RELATIONS_PR"
  },
  "PUBLIC_SAFETY": {
    "label": "Public Safety",
    "value": "PUBLIC_SAFETY"
  },
  "PUBLISHING_INDUSTRY": {
    "label": "Publishing Industry",
    "value": "PUBLISHING_INDUSTRY"
  },
  "RAILROAD_MANUFACTURE": {
    "label": "Railroad Manufacture",
    "value": "RAILROAD_MANUFACTURE"
  },
  "RANCHING": {
    "label": "Ranching",
    "value": "RANCHING"
  },
  "REAL_ESTATE_MORTGAGE": {
    "label": "Real Estate/Mortgage",
    "value": "REAL_ESTATE_MORTGAGE"
  },
  "RECREATIONAL_FACILITIES_SERVICES": {
    "label": "Recreational Facilities/Services",
    "value": "RECREATIONAL_FACILITIES_SERVICES"
  },
  "RELIGIOUS_INSTITUTIONS": {
    "label": "Religious Institutions",
    "value": "RELIGIOUS_INSTITUTIONS"
  },
  "RENEWABLES_ENVIRONMENT": {
    "label": "Renewables/Environment",
    "value": "RENEWABLES_ENVIRONMENT"
  },
  "RESEARCH_INDUSTRY": {
    "label": "Research Industry",
    "value": "RESEARCH_INDUSTRY"
  },
  "RESTAURANTS": {
    "label": "Restaurants",
    "value": "RESTAURANTS"
  },
  "RETAIL_INDUSTRY": {
    "label": "Retail Industry",
    "value": "RETAIL_INDUSTRY"
  },
  "SECURITY_INVESTIGATIONS": {
    "label": "Security/Investigations",
    "value": "SECURITY_INVESTIGATIONS"
  },
  "SEMICONDUCTORS": {
    "label": "Semiconductors",
    "value": "SEMICONDUCTORS"
  },
  "SHIPBUILDING": {
    "label": "Shipbuilding",
    "value": "SHIPBUILDING"
  },
  "SPORTING_GOODS": {
    "label": "Sporting Goods",
    "value": "SPORTING_GOODS"
  },
  "SPORTS": {
    "label": "Sports",
    "value": "SPORTS"
  },
  "STAFFING_RECRUITING": {
    "label": "Staffing/Recruiting",
    "value": "STAFFING_RECRUITING"
  },
  "SUPERMARKETS": {
    "label": "Supermarkets",
    "value": "SUPERMARKETS"
  },
  "TELECOMMUNICATIONS": {
    "label": "Telecommunications",
    "value": "TELECOMMUNICATIONS"
  },
  "TEXTILES": {
    "label": "Textiles",
    "value": "TEXTILES"
  },
  "THINK_TANKS": {
    "label": "Think Tanks",
    "value": "THINK_TANKS"
  },
  "TOBACCO": {
    "label": "Tobacco",
    "value": "TOBACCO"
  },
  "TRANSLATION_LOCALIZATION": {
    "label": "Translation/Localization",
    "value": "TRANSLATION_LOCALIZATION"
  },
  "TRANSPORTATION": {
    "label": "Transportation",
    "value": "TRANSPORTATION"
  },
  "UTILITIES": {
    "label": "Utilities",
    "value": "UTILITIES"
  },
  "VENTURE_CAPITAL_VC": {
    "label": "Venture Capital/VC",
    "value": "VENTURE_CAPITAL_VC"
  },
  "VETERINARY": {
    "label": "Veterinary",
    "value": "VETERINARY"
  },
  "WAREHOUSING": {
    "label": "Warehousing",
    "value": "WAREHOUSING"
  },
  "WHOLESALE": {
    "label": "Wholesale",
    "value": "WHOLESALE"
  },
  "WRITING_EDITING": {
    "label": "Writing/Editing",
    "value": "WRITING_EDITING"
  },
  ...EnumFunctions,
}