/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { toast } from "react-toastify";
import { LocalStorageName } from "./constants";

export const clientApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const refreshApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

clientApi.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshRequest = await refreshApi.post("/auth/refresh-token");

        if (refreshRequest.status === 200) {
          window.localStorage.setItem(
            LocalStorageName,
            JSON.stringify({
              accessTokenExpire: refreshRequest.data.accessTokenExpire,
              refreshTokenExpire: refreshRequest.data.refreshTokenExpire,
            })
          );
          return clientApi(originalRequest);
        }
        window.location.href = "/login";
      } catch (err) {
        window.location.href = "/login";
      }
    } else {
      throw error;
    }
  }
);

// eslint-disable-next-line consistent-return
export const makeRequest = async (url, method, payload, rejectWithValue, showErrors = true) => {
  try {
    const data = await clientApi(url, {
      method,
      data: payload,
    });
    return data?.data;
  } catch (error) {
    const errorData = {
      status: error.response.status,
      message: error.response?.data?.message || null,
    };
    if (error?.code === "ERR_NETWORK") {
      toast.error("Internal Server Error");
    } else if (showErrors) {
      toast.error(error.response?.message || errorData.message);
    }

    return rejectWithValue(errorData.message || error.response.data);
  }
};
