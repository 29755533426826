import { createSlice } from "@reduxjs/toolkit";

import { changePassword, login, logOut, verifyAccount } from "./actions";
import { isUserAuthenticated, isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import { LocalStorageName } from "../../utilities/constants";
import { getCurrentUser } from "../users/actions";

const initialState = {
  loading: false,
  currentUserId: null,
  isFirstLogin: false,
  isAuthenticated: isUserAuthenticated(),
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            accessTokenExpire: payload.accessTokenExpire,
            refreshTokenExpire: payload.refreshTokenExpire,
          })
        );
        return {
          ...state,
          currentUserId: payload.user.id,
          isAuthenticated: true,
        };
      })

      .addCase(verifyAccount.fulfilled, (state, { payload }) => {
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            accessTokenExpire: payload.accessTokenExpire,
            refreshTokenExpire: payload.refreshTokenExpire,
          })
        );
        return {
          ...state,
          isFirstLogin: payload.user.isFirstLogin,
          currentUserId: payload.user.id,
          isAuthenticated: true,
        };
      })

      .addCase(logOut.fulfilled, (state) => {
        window.localStorage.removeItem(LocalStorageName);
        return {
          ...state,
          currentUserId: null,
          isAuthenticated: false,
        };
      })

      .addCase(changePassword.fulfilled, (state) => ({
        ...state,
        isFirstLogin: false,
      }))

      .addCase(getCurrentUser.fulfilled, (state, { payload }) => ({
        ...state,
        currentUserId: payload.id,
      }))

      .addMatcher(isPendingAction("authentication"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("authentication"), (state) => ({
        ...state,
        loading: false,
      }))
      .addMatcher(isFulfilledAction("authentication"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default authenticationSlice.reducer;
